import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../common/Link"
import { YoutubeHelper } from "../../utils/YoutubeHelper"
import MomentDate from "../../utils/MomentDate"
import Button from "../common/Button"
import { useStaticQuery, graphql } from "gatsby"
import BiljetterEventGroup from "./BiljetterEventGroup"
import DuKanskeGillar from "./DuKanskeGillar"
import moment from "moment"
import "moment/locale/sv"

export default function EventSinglePage({ event, lang }) {
  const globalData = useStaticQuery(graphql`
    query {
      wp {
        themeGeneralSettings {
          variabler {
            lankTillMatbestallning
          }
        }
      }
    }
  `)

  //Function to see if input time has passed
  const timeHasPassed = time => {
    return moment(time).isBefore(new Date())
  }

  const matLink =
    globalData.wp.themeGeneralSettings.variabler.lankTillMatbestallning

  const { title, informationProgram, featuredImage } = event
  const imageData = getImage(featuredImage?.node.localFile)
  const {
    youtubeKlipp,
    plats,
    ingang,
    oppnar,
    inslappTillSalong,
    slutar,
    borjar,
    duKanskeGillar,
    aldersgrans,
    langInfo,
    viktigInfoEvent,
    biljettlank,
    biljettslapp,
    biljetterEventGroup,
    underrubrik,
    status,
    startdatum,
    slutdatum,
    forkopMatEvent,
    pris,
    mediaspelare,
    facebookEvent,
    hemsidaArtist,
    instagramProfil,
    spotifyProfilLank,
  } = informationProgram

  const youTubeId = YoutubeHelper(youtubeKlipp)
  const mediaplayers = mediaspelare
  return (
    <div className="pb-8">
      <div className="">
        <GatsbyImage image={imageData} alt={title} className="w-full " />
      </div>
      <div className="max-w-screen-2xl mx-auto px-4 lg:px-8">
        {/* Title header */}
        <div className=" ">
          <div className="flex flex-col lg:flex-row gap-4 py-8 w-full justify-between lg:items-center">
            <header className="flex flex-col gap-2">
              <div className="flex flex-row gap-2  ">
                <div className="flex md:flex-row flex-wrap uppercase font-bold">
                  {slutdatum ? (
                    <>
                      <span>
                        <MomentDate dateString={startdatum} newDate="D MMM" />
                      </span>
                      <span>{" - "}</span>
                      <span>
                        <MomentDate
                          dateString={slutdatum}
                          newDate="D MMM YYYY"
                        />
                      </span>
                    </>
                  ) : (
                    <MomentDate dateString={startdatum} newDate="D MMM YYYY" />
                  )}
                </div>
                {status?.slug !== "aktivt" && (
                  <div className="uppercase ">{status?.name}</div>
                )}
              </div>
              <h1 className=" text-2xl md:text-5xl lg:max-w-3xl">{title}</h1>
              {underrubrik && (
                <div className="uppercase font-bold">{underrubrik}</div>
              )}
            </header>
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-center relative z-20">
              {forkopMatEvent && (
                <div className="py-1">
                  <Button
                    text="Förbeställ mat"
                    theme="light"
                    url={matLink}
                    size="xl"
                  />
                </div>
              )}

              <div className="text-primary font-bold">
                {!biljettslapp || timeHasPassed(biljettslapp) ? (
                  <>
                    {biljettlank && status?.slug !== "installt" ? (
                      <div className="py-1">
                        <Button
                          text={lang === "en" ? "Book tickets" : "Boka biljett"}
                          theme="dark"
                          url={biljettlank}
                          size="xl"
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  "Biljettsläpp: " +
                  moment(biljettslapp).format("D MMM HH:mm, YYYY")
                )}
              </div>

              {/* {!biljettslapp ? (
                <>
                  {biljettlank && status?.slug !== "installt" ? (
                    <div className="py-1">
                      <Button
                        text={lang === "en" ? "Book tickets" : "Boka biljett"}
                        theme="dark"
                        url={biljettlank}
                        size="xl"
                      />
                    </div>
                  ) : null}
                </>
              ) : null} */}
              {biljetterEventGroup && (
                <BiljetterEventGroup tickets={biljetterEventGroup} />
              )}
            </div>
          </div>
        </div>
        {/* /Title header */}
        <section className="grid md:grid-cols-2 gap-8">
          {/* Primary column */}
          <div className="flex flex-col gap-4 lg:gap-8">
            {/* <header className="flex flex-col">
              <h1 className="uppercase text-2xl md:text-5xl">{title}</h1>
              <div className="flex md:flex-row flex-wrap  ">
                <MomentDate dateString={startdatum} newDate="YYYY.MM.DD" />
              </div>
            </header> */}

            {/* {biljettlank && status !== "Inställt" && (
              <div className="block lg:hidden">
                <Button
                  text="Köp biljett"
                  theme="dark"
                  url={biljettlank}
                  size="2xl"
                />
              </div>
            )} */}

            <div className="program-information bg-primary p-4 lg:p-8 text-white  py-4  ">
              {pris && (
                <div className="font-bold">
                  Pris:&nbsp;
                  <span className="font-normal"> {pris}</span>
                </div>
              )}
              {oppnar && (
                <div className="font-bold">
                  Öppnar:&nbsp;
                  <span className="font-normal"> {oppnar}</span>
                </div>
              )}
              {inslappTillSalong && (
                <div className="font-bold">
                  Insläpp till salong:&nbsp;
                  <span className="font-normal"> {inslappTillSalong}</span>
                </div>
              )}
              {borjar && (
                <div className="font-bold">
                  Börjar:&nbsp;
                  <span className="font-normal"> {borjar}</span>
                </div>
              )}
              {slutar && (
                <div className="font-bold">
                  Stänger:&nbsp;
                  <span className="font-normal"> {slutar}</span>
                </div>
              )}
              {plats && (
                <div className="font-bold">
                  Plats:&nbsp;
                  <span className="font-normal">{plats[0].name}</span>
                </div>
              )}
              {ingang && (
                <div className="font-bold">
                  Ingång:&nbsp;
                  <span className="font-normal">{ingang}</span>
                </div>
              )}
              {aldersgrans && (
                <div className="font-bold">
                  Åldersgräns:&nbsp;
                  <span className="font-normal">{aldersgrans}</span>
                </div>
              )}
            </div>
            <div>
              <div className="flex flex-col lg:flex-row gap-4">
                {facebookEvent && (
                  <Button
                    url={facebookEvent}
                    text="Facebook event"
                    theme="light"
                  />
                )}
                {hemsidaArtist && (
                  <Button url={hemsidaArtist} text="Hemsida" theme="light" />
                )}
                {spotifyProfilLank && (
                  <Button
                    url={spotifyProfilLank}
                    text="Spotify"
                    theme="light"
                  />
                )}
                {instagramProfil && (
                  <Button
                    url={instagramProfil}
                    text="Instagram"
                    theme="light"
                  />
                )}
              </div>
            </div>
            {youTubeId && (
              <div className=" embed-container ">
                <iframe
                  width="560"
                  height="315"
                  src={"https://www.youtube.com/embed/" + youTubeId}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            )}
            {mediaplayers && (
              <div className=" flex flex-col gap-4">
                {mediaplayers.map((mediaplayer, i) => {
                  return (
                    <div key={i}>
                      {" "}
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: mediaplayer.embedKod,
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* Secondary column */}
          <div className="flex-1 flex flex-col gap-4 lg:gap-8">
            {/* <div className="">
              <GatsbyImage image={imageData} alt={title} className="w-full" />
            </div> */}
            {langInfo && (
              <div className="p-4 md:p-8 bg-tenth/5 ">
                <div
                  dangerouslySetInnerHTML={{ __html: langInfo }}
                  className="space-y-4 "
                />
              </div>
            )}
            {viktigInfoEvent && (
              <div className="p-4 md:p-8 bg-primary/10 border-primary border ">
                <div
                  dangerouslySetInnerHTML={{ __html: viktigInfoEvent }}
                  className="space-y-4 "
                />
              </div>
            )}
          </div>
        </section>
        {duKanskeGillar && (
          <section className="bg-gradient-to-b from-brandorange to-brandpink my-8 p-4 md:p-8 space-y-4">
            <h4 className="uppercase text-2xl text-center md:pb-4">
              Du kanske också gillar
            </h4>
            <DuKanskeGillar events={duKanskeGillar} />
          </section>
        )}
      </div>
    </div>
  )
}
