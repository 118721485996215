import { func } from "prop-types"
import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import Link from "../common/Link"

export default function BiljetterEventGroup({ tickets }) {
  return (
    <div className="relative">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center w-full justify-center  bg-primary text-white px-3 py-2 lg:px-6 lg:py-2  rounded-full font-bold hover:bg-opacity-75 transition-all focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            Köp Biljett
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 "
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0  origin-top-right  text-right mt-4 bg-white text-primary focus:outline-none">
          {tickets.map((ticket, i) => {
            return (
              <Menu.Item
                as="div"
                key={i}
                className="odd:bg-gray-50 hover:bg-primary/20"
              >
                <Ticket ticket={ticket} />
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Menu>
    </div>

    // <div>
    //   {tickets.map((ticket, i) => {
    //     return <Ticket key={i} ticket={ticket} />
    //   })}
    // </div>
  )
}

function Ticket({ ticket }) {
  const { biljettlankBiljett, namnPaBiljett, prisBiljett } = ticket

  return (
    <Link to={biljettlankBiljett}>
      <div className="p-4 whitespace-nowrap  ">
        {namnPaBiljett}
        <span className="text-gray-900">
          {" ("}
          {prisBiljett}
          {"kr"}
          {")"}
        </span>
      </div>
    </Link>
  )
}
