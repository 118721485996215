import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"

import { getLongDate } from "../utils/getLongDate"
import EventSinglePage from "../components/events/EventSinglePage"

export default function EventTemplate(props) {
  const event = props.data.wpEvent
  const { startdatum } = props.data.wpEvent.informationProgram
  const langInfoTrimmed = event.informationProgram.langInfo
    ? event.informationProgram.langInfo
        .substring(0, 150)
        .replace(/<[^>]*>?/gm, "")
    : "" || "Kolla in detta event på Slagthuset i Malmö!"

  const metaDescription = event.seo.metaDesc || langInfoTrimmed
  const lang = props.pageContext.langKey
  return (
    <Layout>
      <SEO
        keywords={[`Slagthuset`, `malmö`, `event`]}
        title={event.title + ", " + getLongDate(startdatum, "Do MMMM YYYY")}
        description={metaDescription}
      />
      <EventSinglePage event={event} lang={lang} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpEvent(id: { eq: $id }) {
      title
      slug
      seo {
        metaDesc
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                aspectRatio: 2
                layout: FULL_WIDTH
              )
            }
          }
        }
      }

      informationProgram {
        borjar
        plats {
          name
        }
        ingang
        inslappTillSalong
        underrubrik

        langInfo
        viktigInfoEvent
        biljettlank
        biljettslapp
        facebookEvent
        hemsidaArtist
        instagramProfil
        spotifyProfilLank
        oppnar
        pris
        status {
          slug
          name
        }
        aldersgrans
        youtubeKlipp
        forkopMatEvent
        slutar
        startdatum
        slutdatum
        mediaspelare {
          embedKod
        }
        biljetterEventGroup {
          biljettlankBiljett
          namnPaBiljett
          prisBiljett
        }
        duKanskeGillar {
          ... on WpEvent {
            title
            id
            slug
            uri
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(aspectRatio: 2)
                  }
                }
              }
            }
            informationProgram {
              borjar

              underrubrik
              startdatum
            }
          }
        }
      }
    }
  }
`
