import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../common/Link"

import MomentDate from "../../utils/MomentDate"
import EventImage from "./EventImage"
import Button from "../common/Button"
export default function DuKanskeGillar({ events }) {
  return (
    <div className="grid md:grid-cols-3 gap-4 md:gap-8 auto-cols-auto">
      {events?.slice(0, 3).map(event => {
        return <Event key={event.id} event={event} />
      })}
    </div>
  )
}

function Event({ event }) {
  const { title, featuredImage, informationProgram, uri } = event
  const { kortInfo, startdatum } = informationProgram
  const img = featuredImage.node.localFile.childImageSharp
  return (
    <div className="bg-white">
      <div className=" flex flex-col h-full text-xl shadow-md shadow-thirteenth/10 group">
        <div className="relative">
          <div className="relative">
            <div className="overflow-hidden">
              <div className="zoom-image">
                <Link to={uri} className="block  bg-white  relative ">
                  <EventImage img={img} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className=" p-4 flex flex-col justify-between gap-4 h-full">
          <div className=" font-bold ">
            {" "}
            <Link to={uri}>{title}</Link>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="uppercase text-sm">
              <MomentDate dateString={startdatum} newDate="D MMM YYYY" />
            </div>
            <div>
              <div className=" flex flex-row gap-2 items-center">
                {/* <div>
                  <Button text="Köp biljett" theme="dark" />
                </div> */}
                <div>
                  <Button text="Läs mer" url={uri} theme="dark" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
